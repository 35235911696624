.prayer-time-box {
  margin: 1rem auto;
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left; /* Align prayer names and times to left */
  background-color: #fff;
  display: flex; /* Arrange content in a column */
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; 
  align-items: center;
}

.prayer-time-box.upcoming {
  background-color: #f0f8ff; /* Lighter color for upcoming prayers */
}

.prayer-time-box.ongoing {
  background-color: #e0ffff; /* Soft blue for ongoing prayers */
}

.prayer-time-box.missed {
  background-color: #f2dede; /* Light red for missed prayers */
}

.prayer-name {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.prayer-time {
  font-size: 1.5rem; /* Slightly larger font for prayer times */
}

.prayer-status {
  font-size: 1rem;
  color: #888;
}